/* eslint-disable prettier/prettier */
// painel do cliente
export const URL_API = process.env.REACT_APP_API_URL ?? 'http://localhost:8082/api/';
export const MANAGER_V1_URL = process.env.REACT_APP_MANAGER_V1_URL ?? 'http://localhost:8081/';
export const LOGIN_PATH = 'v1/login';
export const EMPRESA_PATH = 'local/empresa';
export const FILIAL_PATH = 'local/filialtenant';
export const FILIAL_INTEG_PATH = 'geral/integracao/filiais';
export const INTEGRACAO_PATH = 'geral/integracao';
export const VERSAO_APP_PATH = 'geral/aplicativo/versao';
export const DISPOSITIVO_PATH = 'local/dispositivo';
export const USUARIOS_PATH = 'local/usuario';
export const PERFIS_EMPRESA_PATH = 'local/perfil/empresa';
export const PERFIS_PATH = 'local/perfil';
export const MENUS_PERFIL = 'local/perfil/menus';
export const CONFIG_GLOBAL = 'geral/dispositivo/config';
export const SERVICOS_PATH = 'geral/status/servico';
export const AUTH_ME_PATH = 'v1/auth/me';
export const HISTORICO_NOTAS_PATH = 'tenant/nota';
export const ACESSOS_MENU_PATH = 'geral/acessos/arvore/menu';
export const ELEM_INTEG_PATH = 'tenant/elementos';
export const VENDEDORES_PATH = 'tenant/vendedor';
export const ALTERAR_SENHA_PATH = 'tenant/usuario/modificarSenha';
export const RECUPERACAO_SENHA_PATH = 'v1/resetPassword';
export const METAS_PATH = 'tenant/meta';
export const TITULOS_PATH = 'tenant/financeiro/lista';
export const PRODUTO_PATH = 'tenant/produto';
export const PRAZO_PAGAMENTO_PATH = 'tenant/prazo-pagamento/consulta';
export const TABELA_PRECO_PATH = 'tenant/tabela-preco/consulta';
export const PRODUTO_STATUS_PATH = 'tenant/produto/status';
export const PRODUTO_GRUPO_PATH = 'tenant/produto/grupo';
export const PRODUTO_SUBGRUPO_PATH = 'tenant/produto/subgrupo';
export const CLIENTE_PATH = 'tenant/clientes';
export const CLIENTE_STATUS_PATH = 'tenant/clientes/status';
export const CLIENTE_ATIVIDADE_PATH = 'tenant/clientes/atividades';
export const FORMAS_PAGAMENTO_PATH = 'tenant/formapagamento/consulta';
export const LISTAR_SENHA_PATH = 'geral/cercaeletronica/empresa';
export const LISTAR_LIBERADOS_SENHA_PATH =
    'geral/cercaeletronica/pedidos/liberados';
export const MOTIVO_PATH = 'geral/cercaeletronica/motivo';
export const CONFIG_PW_PATH = 'geral/pedidoweb/tenant/config';
export const CONFIG_PW_POST_PATH = 'geral/pedidoweb/tenant/array/config';
export const GRUPOS_PATH = 'local/grupos';
export const GRAFICOS_PATH = 'local/graficos';
export const GEOLOCALIZAR_PATH = 'tenant/mapas/rastrear';
export const PED_ANDAMENTO_PATH = 'tenant/pedido/andamento';
export const PED_DETALHES_PATH = 'tenant/pedido/detalhe';
export const PED_VENDEDOR_PATH = 'tenant/pedido/coordenada/vendedor';
export const PED_LIST_COORDENADA_PATH = 'tenant/pedido/coordenada/lista';
export const PED_COORD_TOTAIS_PATH = 'tenant/pedido/coordenada/total';
export const PED_COORD_CIDEST_PATH = 'tenant/pedido/coordenada/estado/cidade';
export const VISITA_SIMPLES = 'tenant/visita/agenda/simples';
export const DETALHAMENTO_VISITA = 'tenant/visita/info/';
export const CONFIG_PATH = '/configuracoes';
export const PEDIDOS_POR_DIAS = 'tenant/pedido/por-dias';
export const VENDEDORES_POR_DIAS = 'tenant/vendedor/card';

// painel do suporte
export const LISTA_EMPRESAS_PATH = '/a/empresas/';
export const LISTA_INTEGRACOES_PATH = '/a/integracoes/';
export const LISTA_GRAFICOS_PATH = '/a/graficos/';
export const LISTA_GRUPOS_PATH = '/a/grupos/';
export const LISTA_RELATORIOS_PATH = '/a/relatorios/';
export const LISTA_VERSOES_PATH = '/a/versoes/';
export const CONFIG_PATH_ADM = '/a/configuracoes';

export const AFV_PATH = '';
export const ADMIN_PATH = '';
export const CONFIG_DB_PATH = '';
export const PEDIDO_WEB_PATH = '';
export const NOTIFI_VERSAO_PATH = '';

// components route path
export const ALTERAR_SENHA_ROUTE_PATH = '/alterar-senha';
export const ROOT_ROUTE_PATH = '/';

export const Sinc = {
    Tab: {
        GERAL: '1',
        BASICO: '2',
        CLIENTE: '3',
        PRODUTO: '4',
        VENDEDOR: '5',
        METODO_PAG: '6',
        FAVORITOS: '7'
    },
    Grupo: {
        BASICO: 2,
        CLIENTE: 3,
        PRODUTO: 4,
        VENDEDOR: 5,
        METODO_PAG: 6
    },
    Tipo: {
        ONLINE: 0,
        PROGRAMADA: 1
    },
    Valor: {
        CHECKED: 1
    }
};

export const Visita = {
    Status: {
        ABERTO: {id: 0, label: 'Aberto'},
        SEM_VISITA: {id: 1, label: 'Sem Visita'},
        SEM_VENDA: {id: 2, label: 'Sem Venda'},
        COM_VENDA: {id: 3, label: 'Com Venda'},
        FINALIZADA: {id: 5, label: 'Finalizada'},
        FINALIZADA_FORA_DE_ROTA: {id: 7, label: 'Finalizada Fora de Rota'},
        FINALIZADA_FORA_DE_ROTEIRO: {id: 7, label: 'Finalizada Fora do Roteiro'}
    }
};

export const REQUEST_INTERVAL = 2000;
